/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import SEO from "./seo"
import React, {useState, useEffect} from "react"
import ReactGA from "react-ga"
import Header from "./header"
import Footer from "./footer"
import Isi from "./isi"
import favicon from "../images/favicon.png"
import Helmet from "react-helmet"
import HcpConfirmationOverlay from "./hcp-overlay"
import QuizOverlay from "./quiz-overlay"
import CoverageOverlay from "./coverage-overlay"
import track from "../components/track"
import "./layout.css"
import NDCConfirmationOverlay from "./ndc-overlay"

const Layout = props => {
  const {
    children,
    pageTitle,
    pageType,
    pageSection,
    pageClass,
    pageDescription,
    pageName,
    showQuiz,
    setQuizOverlayOpen,
    showCoverage,
    setCoverageOverlayOpen,
	showNDC,
    setNDCOverlayOpen
  } = props

  const [openCoverageOverlay, setOpenCoverageOverlay] = useState(0);

  const [openNDCOverlay, setOpenNDCOverlay] = useState(0);

  // Google analytics
  if (typeof window !== "undefined") {
    var host = window.location.hostname
    var GACode = "UA-155993142-4"
    if (host.includes("localhost") || host.includes("bgbdigital")) {
      GACode = "UA-45051197-16"
    }
    ReactGA.initialize(GACode)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  const openCoverageOverlayPopup = (openPopup) => {
    setOpenCoverageOverlay(openPopup);
  }

  const openNDCOverlayPopup = (openPopup) => {
    setOpenNDCOverlay(openPopup);
  }

  useEffect(() => {

    // add google tag manager script
    const gtmScript = document.createElement('script');
    gtmScript.type = 'text/javascript';
    gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=DC-10089119";
    document.body.appendChild(gtmScript);

    // add global floodlight tags script
    const floodlightScript = document.createElement('script');
    floodlightScript.type = 'text/javascript';
    floodlightScript.src = "/floodlight-global.js";
    document.body.appendChild(floodlightScript);

    // pulse tracking
    if (typeof window !== "undefined") {
      var urlHost = window.location.hostname
      var pulseIdScriptContent, pulseNoscriptContent;
      const pulseIdScript = document.createElement('script');
      pulseIdScript.type = 'text/javascript';
      const pulseScript = document.createElement('script');
      pulseScript.type = 'text/javascript';
      const pulseNoscriptNode = document.createElement('noscript');
      if (urlHost.includes('staging')){
        pulseIdScriptContent = 'var pulseSiteID = 24';
        pulseScript.src = '//tracking-beta.explorepulse.com/track.js';
        pulseNoscriptContent = '<p><img src="//tracking-beta.explorepulse.com/track-noscript.php?idsite=24&rec=1" style="border:0;" alt="" /></p>'
      } else {
        pulseIdScriptContent = 'var pulseSiteID = 29';
        pulseScript.src = '//tracking.explorepulse.com/track.js';
        pulseNoscriptContent = '<p><img src="//tracking.explorepulse.com/track-noscript.php?idsite=29&rec=1" style="border:0;" alt="" /></p>';
      }
      pulseIdScript.innerHTML = pulseIdScriptContent;
      document.head.appendChild(pulseIdScript);
      document.head.appendChild(pulseScript);
      pulseNoscriptNode.innerHTML = pulseNoscriptContent;
      document.body.appendChild(pulseNoscriptNode);
    }

    // track when a page has been open for 30+ seconds
    const labelStart = pageType === 'hcp' ? 'HCP' : 'DTC';
    let pageLoadTimeout = setTimeout(function(){
      track(pageType + ' ' + pageName + ' page', 'Time Spent', labelStart + ' Global - Time Spent 30s+');
      track('Global', 'Time Spent', labelStart + ' Global - Time Spent 30s+ v2');
    },30000);

    return () => {
      clearTimeout(pageLoadTimeout);
    }
  }, [])

  const sectionClass = pageSection ? pageSection : ""
  const thisPageClass = pageClass ? pageClass : ""

  return (

            <>
              <SEO title={pageTitle} description={pageDescription} pageClass={pageClass} />
              <Helmet>
                <link rel="icon" href={favicon} />
                <script src="https://code.jquery.com/jquery-3.5.1.min.js" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
              </Helmet>
              <div id="wrapper" className={pageType}>
                <Header
                  pageType={pageType}
                  sectionClass={sectionClass}
                  thisPageClass={thisPageClass}
                />
                
                <main className={thisPageClass}>{children}</main>
                <Isi pageType={pageType} />
                <Footer pageType={pageType} />
              </div>
              <HcpConfirmationOverlay setCoverageOverlayOpen={openCoverageOverlayPopup} pageType={pageType} className={`hide`} />
              <QuizOverlay setQuizOverlayOpen={setQuizOverlayOpen} showQuiz={showQuiz} />
              <CoverageOverlay setCoverageOverlayOpen={openCoverageOverlayPopup} showCoverage={openCoverageOverlay}/>
              <NDCConfirmationOverlay />
            </>
  )
}

export default Layout
