import React from "react"
import { NavProvider, NavConsumer } from "./navContext"


const NDCConfirmationOverlay = () => {
  return (
    <NavConsumer>
      {value => {
        
        const { setNDCViewed, NDCViewed } = value

        const closeOverlay = () => {

          setNDCViewed(true);

        }

        
        return (
          <section id="ndc-overlay" 
          className={`modal ${NDCViewed ? "" : "active"}`}
          >
            <div className="window">
                <button aria-label="click to close this message" id="close-button" className="close-modal close" onClick={closeOverlay}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/>
                    </svg>
                </button>
                <div className="content">
                  <h3>Important Notice</h3>
                <h4>New MOVANTIK National Drug Code (NDC) Numbers</h4>
                <p><strong>Pharmacists, Healthcare Providers, and Patients</strong><br />
                Please note the following new MOVANTIK NDCs:</p>
                <ul>
                  <li>MOVANTIK 12.5 mg: <strong>82625-8801-1</strong></li>
                  <li>MOVANTIK 25 mg: <strong>82625-8802-1</strong></li>
                </ul>
                {/* <p>Please be sure to use these NDC numbers when filling a MOVANTIK prescription.</p> */}
                <p className="smaller">MOVA-1123-332</p>
                </div>
            </div>
        </section>
        )
      }}
    </NavConsumer>
  )
}

export default NDCConfirmationOverlay